import React from 'react'
import { createContext } from 'react'

const AboutContext = createContext()

export const AboutProvider = ({ children }) => {
  const aboutData = {
    education: [
      {
        school: 'UW-Milwaukee',
        years: '2013-2015',
        degree: 'B.S. Information Science & Technology',
      },
      {
        school: 'UW-Barron County',
        years: '2010-2012',
        degree: 'A.A.S Arts & Sciences',
      },
    ],
    companies: [
      {
        name: 'TEKsystems / Peraton / CDC (Contract)',
        years: '2021 - present',
        title: 'Applications Programmer / Analyst',
      },
      {
        name: 'Bear Group',
        years: '2019 - 2021',
        title: 'Web Developer',
      },
      {
        name: 'Eaton Partners',
        years: '2018 - 2019',
        title: 'Web & Marketing Consultant',
      },
      {
        name: 'Marketing In Color',
        years: '2016-2018',
        title: 'Web Developer',
      },
      {
        name: 'Sterling Data Center',
        years: '2014 - 2016',
        title: 'IT Specialist',
      },
    ],
  }
  return (
    <AboutContext.Provider value={aboutData}>{children}</AboutContext.Provider>
  )
}

export default AboutContext
