import React, { useContext } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Container, Row, Col } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import AboutContext, { AboutProvider } from '../context/AboutContext'

const About = (props) => {
  let data = props.data.contentfulPage

  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  const textAreaStyles = {
    gridColumnStart: 'span 2',
  }

  const imgStyles = {
    gridColumnStart: 'span 2',
  }

  const skillsCol = {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  }

  const ulStyles = {
    paddingLeft: 0,
    listStyle: 'none',
  }

  const liStyles = {
    marginBottom: '15px',
  }

  const AboutSkillsSection = () => {
    return (
      <div className="myCol font-small" style={skillsCol}>
        <h3>Passion & Personality</h3>
        <p className="font-weight-bold">
          This section is being written, be right back...
        </p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </div>
    )
  }

  const AboutEducationSection = () => {
    const aboutData = useContext(AboutContext)

    return (
      <div className="myCol myCol--education font-small">
        <h3>Education</h3>
        <ul style={ulStyles}>
          {aboutData.education.map((school) => {
            return (
              <li style={liStyles}>
                <p className="font-weight-bold">{school.school}</p>
                <p>{school.degree}</p>
              </li>
            )
          })}
        </ul>
        {/* experience loop */}
      </div>
    )
  }

  const AboutExperienceSection = () => {
    const aboutData = useContext(AboutContext)

    return (
      <div className="myCol myCol--experience font-small">
        <h3>Experience</h3>
        <ul style={ulStyles}>
          {aboutData.companies.map((company) => {
            return (
              <li style={liStyles}>
                <p className="font-weight-bold">{company.name}</p>
                <p>{company.years}</p>
                <p>{company.title}</p>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <Layout location={props.location}>
      <Seo
        title="About | Adam Doe"
        description={'nothin'}
        pathname={data.slug}
        article
      />
      <AboutProvider>
        <div className="about">
          <Container>
            <Row>
              <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
                <div className="grid">
                  <img
                    src={data.image.file.url}
                    alt="Camping"
                    className="img img-fluid shadow-2xl br-15"
                    style={imgStyles}
                  />
                  <div className="text-area" style={textAreaStyles}>
                    <h1>{data.heading}</h1>
                    {documentToReactComponents(
                      JSON.parse(data.body.raw),
                      options
                    )}
                  </div>
                  <AboutEducationSection />
                  <AboutExperienceSection />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </AboutProvider>
    </Layout>
  )
}

export const data = graphql`
  query AboutQuery {
    contentfulPage(slug: { eq: "/about" }) {
      id
      slug
      heading
      image {
        id
        file {
          url
        }
      }
      body {
        raw
      }
    }
  }
`

export default About
